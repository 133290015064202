import { Container, Grid, Paper } from '@material-ui/core'
import React, { useState } from 'react'
import { components, useTranslation } from 'cng-web-lib'

import BatchDataConfigViewForm from './BatchDataConfigViewForm'
import BatchDataConfigAddForm from './BatchDataConfigAddForm'
import BatchDataConfigEditForm from './BatchDataConfigEditForm'
import Namespace from 'src/constants/locale/Namespace'
import BatchDataConfigKeys from 'src/constants/locale/key/BatchDataConfig'
import {
  constants,
} from 'cng-web-lib'

const {
  locale: {
    key: {
      UiComponentKeys,
    },
  },
} = constants

const { CngTabs } = components

function AddPage({ history, showNotification }) {
  const [lockedOnFirstTab, setLockedOnFirstTab] = useState(true)
  const [batchConfigDataId, setBatchConfigDataId] = useState('')
  const { translate } = useTranslation([
    Namespace.BATCH_DATA_CONFIG,
    Namespace.UI_COMPONENT
  ])
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let batchDataConfig = translate(
      Namespace.BATCH_DATA_CONFIG,
      BatchDataConfigKeys.TITLE
    )
    let tabLockedMessage = translate(
      Namespace.UI_COMPONENT,
      UiComponentKeys.Tabs.PLEASE_SUBMIT_FIRST,
      {
        title: batchDataConfig
      }
    )

    return {
      batchDataConfig,
      tabLockedMessage
    }
  }

  function makeBatchDataConfigForm() {
    if (batchConfigDataId === '') {
      return (
        <BatchDataConfigAddForm
          history={history}
          showNotification={showNotification}
          onPostSubmitSuccess={(datum) => {
            setBatchConfigDataId(datum.id)
            setLockedOnFirstTab(false)
          }}
        />
      )
    } else {
      return (
        <BatchDataConfigEditForm
          showNotification={showNotification}
          id={batchConfigDataId}
        />
      )
    }
  }

  return (
    <Grid container spacing={3}>
      {/* <Grid item xs={12}>
        <Paper>
          <Container maxWidth='xl'>
            <BatchDataConfigViewForm
              showNotification={showNotification}
              id={batchConfigDataId}
            />
          </Container>
        </Paper>
      </Grid> */}
      <Grid item xs={12}>
        <CngTabs
          headerColor='primary'
          tabs={[
            {
              tabName: translatedTextsObject.batchDataConfig,
              tabContent: makeBatchDataConfigForm()
            },
          ]}
          lockedOnFirstTab={lockedOnFirstTab}
          tabLockedMessage={translatedTextsObject.tabLockedMessage}
        />
      </Grid>
    </Grid>
  )
}

export default AddPage