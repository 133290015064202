import React from 'react'
import Namespace from 'src/constants/locale/Namespace'
import BatchDataConfigKeys from 'src/constants/locale/key/BatchDataConfig'
import { Card, CardContent, Grid } from '@material-ui/core'
import {
  components,
  DataFlattener,
} from 'cng-web-lib'
import makeValidationSchema from './BatchDataConfigMakeValidationSchema'
import { useTranslation } from 'cng-web-lib'

const {
  card: {
    CngSimpleCardHeader,
  },
  form: {
    field: {
      CngTextField,
    },
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  configCode: "",
  configValue1: "",
  configValu2: "",
  configValu3: "",
  configDescription: "",
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, showNotification, shouldHideMap }) {
  const { translate } = useTranslation(Namespace.BATCH_DATA_CONFIG)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let batchDataConfig = translate(
      Namespace.BATCH_DATA_CONFIG,
      BatchDataConfigKeys.TITLE
    )
    let configCode = translate(
      Namespace.BATCH_DATA_CONFIG,
      BatchDataConfigKeys.CONFIG_CODE
    )
    let configValue1 = translate(
      Namespace.BATCH_DATA_CONFIG,
      BatchDataConfigKeys.CONFIG_VALUE1
    )
    let configValu2 = translate(
      Namespace.BATCH_DATA_CONFIG,
      BatchDataConfigKeys.CONFIG_VALU2
    )
    let configValu3 = translate(
      Namespace.BATCH_DATA_CONFIG,
      BatchDataConfigKeys.CONFIG_VALU3
    )
    let configDescription = translate(
      Namespace.BATCH_DATA_CONFIG,
      BatchDataConfigKeys.CONFIG_DESCRIPTION
    )

    return {
      batchDataConfig,
      configCode,
      configValue1,
      configValu2,
      configValu3,
      configDescription
    }
  }

  return (
    <Card>
      <CngSimpleCardHeader title={translatedTextsObject.batchDataConfig} />
      <CardContent>
        <Grid container spacing={3}>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.configCode}>
            <CngTextField
              name="configCode"
              label={translatedTextsObject.configCode + " *"}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.configValue1}>
            <CngTextField
              name="configValue1"
              label={translatedTextsObject.configValue1}
              disabled={disabled}
              multiline
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.configValu2}>
            <CngTextField
              name="configValu2"
              label={translatedTextsObject.configValu2}
              disabled={disabled}
              multiline
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.configValu3}>
            <CngTextField
              name="configValu3"
              label={translatedTextsObject.configValu3}
              disabled={disabled}
              multiline
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.configDescription}>
            <CngTextField
              name="configDescription"
              label={translatedTextsObject.configDescription}
              disabled={disabled}
            />
          </CngGridItem>
        </Grid>
      </CardContent>
    </Card>
  )
}

function toClientDataFormat(serverData) {
  let localData = DataFlattener.parse(serverData);
  return localData;
}

function toServerDataFormat(localData) {
  return DataFlattener.unflatten(localData);
}

const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default FormProperties
