import { Card, CardContent, Grid } from '@material-ui/core'
import {
  components,
  constants,
  useTranslation
} from 'cng-web-lib'

import BatchMilestoneApiUrls from 'src/apiUrls/BatchMilestoneApiUrls'
import React from 'react'
import BatchMilestoneKeys from 'src/constants/locale/key/BatchMilestone'
import Namespace from 'src/constants/locale/Namespace'

const {
  card: { CngSimpleCardHeader },
  table: {
    CngCrudTable,
    DateTimeRangeFilter: CngDateTimeRangeFilter,
    useDefaultNotification
  }
} = components

const {
  locale: {
    key: { UiComponentKeys }
  }
} = constants

function TablePage(props) {
  const {
    location: { pathname },
    showNotification
  } = props

  const notification = useDefaultNotification(showNotification)
  const { translate } = useTranslation([
    Namespace.UI_COMPONENT,
    Namespace.BATCH_MILESTONE
  ])
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let batchMilestone = translate(
      Namespace.BATCH_MILESTONE,
      BatchMilestoneKeys.TITLE
    )
    let tableTitle = translate(
      Namespace.UI_COMPONENT,
      UiComponentKeys.Table.TITLE,
      {
        nameTitleised: batchMilestone
      }
    )
    let id = translate(
      Namespace.BATCH_MILESTONE,
      BatchMilestoneKeys.ID
    )
    let processedDate = translate(
      Namespace.BATCH_MILESTONE,
      BatchMilestoneKeys.PROCESSED_DATE
    )
    let recordStatus = translate(
      Namespace.BATCH_MILESTONE,
      BatchMilestoneKeys.RECORD_STATUS
    )
    let type = translate(
      Namespace.BATCH_MILESTONE,
      BatchMilestoneKeys.TYPE
    )
    let source = translate(
      Namespace.BATCH_MILESTONE,
      BatchMilestoneKeys.SOURCE
    )
    let portofstatus = translate(
      Namespace.BATCH_MILESTONE,
      BatchMilestoneKeys.PORTOFSTATUS
    )
    let description = translate(
      Namespace.BATCH_MILESTONE,
      BatchMilestoneKeys.DESCRIPTION
    )
    let bookingref = translate(
      Namespace.BATCH_MILESTONE,
      BatchMilestoneKeys.BOOKINGREF
    )
    let details = translate(
      Namespace.BATCH_MILESTONE,
      BatchMilestoneKeys.DETAILS
    )
    let party = translate(
      Namespace.BATCH_MILESTONE,
      BatchMilestoneKeys.PARTY
    )
    let code = translate(
      Namespace.BATCH_MILESTONE,
      BatchMilestoneKeys.CODE
    )
    let eventdate = translate(
      Namespace.BATCH_MILESTONE,
      BatchMilestoneKeys.EVENTDATE
    )
    let eventtime = translate(
      Namespace.BATCH_MILESTONE,
      BatchMilestoneKeys.EVENTTIME
    )
    let eventTimezone = translate(
      Namespace.BATCH_MILESTONE,
      BatchMilestoneKeys.EVENT_TIMEZONE
    )
    let contNo = translate(
      Namespace.BATCH_MILESTONE,
      BatchMilestoneKeys.CONT_NO
    )
    let bLNum = translate(
      Namespace.BATCH_MILESTONE,
      BatchMilestoneKeys.B_L_NUM
    )
    let eventDetails = translate(
      Namespace.BATCH_MILESTONE,
      BatchMilestoneKeys.EVENT_DETAILS
    )
    let vesselName = translate(
      Namespace.BATCH_MILESTONE,
      BatchMilestoneKeys.VESSEL_NAME
    )
    let voyageNum = translate(
      Namespace.BATCH_MILESTONE,
      BatchMilestoneKeys.VOYAGE_NUM
    )
    let voyageNo = translate(
      Namespace.BATCH_MILESTONE,
      BatchMilestoneKeys.VOYAGE_NO
    )
    let remarks = translate(
      Namespace.BATCH_MILESTONE,
      BatchMilestoneKeys.REMARKS
    )

    return {
      batchMilestone,
      tableTitle,
      id,
      processedDate,
      recordStatus,
      type,
      source,
      portofstatus,
      description,
      bookingref,
      details,
      party,
      code,
      eventdate,
      eventtime,
      eventTimezone,
      contNo,
      bLNum,
      eventDetails,
      vesselName,
      voyageNum,
      voyageNo,
      remarks
    }
  }

  const columns = [
    {
      field: "id",
      title: translatedTextsObject.id,
    },
    {
      field: "processedDate",
      title: translatedTextsObject.processedDate,
      type: "datetime",
      filterComponent: CngDateTimeRangeFilter,
    },
    {
      field: "recordStatus",
      title: translatedTextsObject.recordStatus,
    },
    {
      field: "type",
      title: translatedTextsObject.type,
    },
    {
      field: "source",
      title: translatedTextsObject.source,
    },
    {
      field: "portofstatus",
      title: translatedTextsObject.portofstatus,
    },
    {
      field: "description",
      title: translatedTextsObject.description,
    },
    {
      field: "bookingref",
      title: translatedTextsObject.bookingref,
    },
    {
      field: "details",
      title: translatedTextsObject.details,
    },
    {
      field: "party",
      title: translatedTextsObject.party,
    },
    {
      field: "code",
      title: translatedTextsObject.code,
    },
    {
      field: "eventdate",
      title: translatedTextsObject.eventdate,
    },
    {
      field: "eventtime",
      title: translatedTextsObject.eventtime,
    },
    {
      field: "eventTimezone",
      title: translatedTextsObject.eventTimezone,
    },
    {
      field: "contNo",
      title: translatedTextsObject.contNo,
    },
    {
      field: "bLNum",
      title: translatedTextsObject.bLNum,
    },
    {
      field: "eventDetails",
      title: translatedTextsObject.eventDetails,
    },
    {
      field: "vesselName",
      title: translatedTextsObject.vesselName,
    },
    {
      field: "voyageNum",
      title: translatedTextsObject.voyageNum,
    },
    {
      field: "voyageNo",
      title: translatedTextsObject.voyageNo,
    },
    {
      field: "remarks",
      title: translatedTextsObject.remarks,
    }
  ]

  return (
    <Card>
      <CngSimpleCardHeader title={translatedTextsObject.tableTitle} />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CngCrudTable
              {...props}
              fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
              addRoute={`${pathname}/add`}
              columns={columns}
              del={{ url: BatchMilestoneApiUrls.DELETE }}
              editRoute={`${pathname}/edit`}
              exportData={{ url: BatchMilestoneApiUrls.EXPORT }}
              fetch={{ url: BatchMilestoneApiUrls.GET }}
              idAccessor="id"
              notification={notification}
              //tableConfigurationCode="REPLACE_WITH_CORE_TABLE_CONFIG_CODE"
              viewRoute={`${pathname}/view`}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default TablePage
