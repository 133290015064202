import React from 'react'
import Namespace from 'src/constants/locale/Namespace'
import BatchMilestoneKeys from 'src/constants/locale/key/BatchMilestone'
import { Grid } from '@material-ui/core'
import {
	components,
    DataFlattener,
    DateTimeFormatter,
} from 'cng-web-lib'
import makeValidationSchema from './BatchMilestoneMakeValidationSchema'
import { useTranslation } from 'cng-web-lib'
import moment from 'moment'

const {
    form: {
        field: {
            CngTextField,
            CngDateTimeField,
        },
    },
    CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  id: "",
  processedDate: "",
  recordStatus: "",
  type: "",
  source: "",
  portofstatus: "",
  description: "",
  bookingref: "",
  details: "",
  party: "",
  code: "",
  eventdate: "",
  eventtime: "",
  eventTimezone: "",
  contNo: "",
  bLNum: "",
  eventDetails: "",
  vesselName: "",
  voyageNum: "",
  voyageNo: "",
  remarks: "",
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, showNotification, shouldHideMap }) {
  const { translate } = useTranslation(Namespace.BATCH_MILESTONE)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let batchMilestone = translate(
      Namespace.BATCH_MILESTONE,
      BatchMilestoneKeys.TITLE
    )
    let undefined = translate(
      Namespace.BATCH_MILESTONE,
      BatchMilestoneKeys.undefined
    )
    let id = translate(
      Namespace.BATCH_MILESTONE,
      BatchMilestoneKeys.ID
    )
    let processedDate = translate(
      Namespace.BATCH_MILESTONE,
      BatchMilestoneKeys.PROCESSED_DATE
    )
    let recordStatus = translate(
      Namespace.BATCH_MILESTONE,
      BatchMilestoneKeys.RECORD_STATUS
    )
    let type = translate(
      Namespace.BATCH_MILESTONE,
      BatchMilestoneKeys.TYPE
    )
    let source = translate(
      Namespace.BATCH_MILESTONE,
      BatchMilestoneKeys.SOURCE
    )
    let portofstatus = translate(
      Namespace.BATCH_MILESTONE,
      BatchMilestoneKeys.PORTOFSTATUS
    )
    let description = translate(
      Namespace.BATCH_MILESTONE,
      BatchMilestoneKeys.DESCRIPTION
    )
    let bookingref = translate(
      Namespace.BATCH_MILESTONE,
      BatchMilestoneKeys.BOOKINGREF
    )
    let details = translate(
      Namespace.BATCH_MILESTONE,
      BatchMilestoneKeys.DETAILS
    )
    let party = translate(
      Namespace.BATCH_MILESTONE,
      BatchMilestoneKeys.PARTY
    )
    let code = translate(
      Namespace.BATCH_MILESTONE,
      BatchMilestoneKeys.CODE
    )
    let eventdate = translate(
      Namespace.BATCH_MILESTONE,
      BatchMilestoneKeys.EVENTDATE
    )
    let eventtime = translate(
      Namespace.BATCH_MILESTONE,
      BatchMilestoneKeys.EVENTTIME
    )
    let eventTimezone = translate(
      Namespace.BATCH_MILESTONE,
      BatchMilestoneKeys.EVENT_TIMEZONE
    )
    let contNo = translate(
      Namespace.BATCH_MILESTONE,
      BatchMilestoneKeys.CONT_NO
    )
    let bLNum = translate(
      Namespace.BATCH_MILESTONE,
      BatchMilestoneKeys.B_L_NUM
    )
    let eventDetails = translate(
      Namespace.BATCH_MILESTONE,
      BatchMilestoneKeys.EVENT_DETAILS
    )
    let vesselName = translate(
      Namespace.BATCH_MILESTONE,
      BatchMilestoneKeys.VESSEL_NAME
    )
    let voyageNum = translate(
      Namespace.BATCH_MILESTONE,
      BatchMilestoneKeys.VOYAGE_NUM
    )
    let voyageNo = translate(
      Namespace.BATCH_MILESTONE,
      BatchMilestoneKeys.VOYAGE_NO
    )
    let remarks = translate(
      Namespace.BATCH_MILESTONE,
      BatchMilestoneKeys.REMARKS
    )

    return {
      batchMilestone,
      id,
      processedDate,
      recordStatus,
      type,
      source,
      portofstatus,
      description,
      bookingref,
      details,
      party,
      code,
      eventdate,
      eventtime,
      eventTimezone,
      contNo,
      bLNum,
      eventDetails,
      vesselName,
      voyageNum,
      voyageNo,
      remarks
    }
  }

	return (
        <Grid container spacing={3}>
        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.id}>
          <CngTextField
            name="id"
            label={translatedTextsObject.id}
            disabled={disabled}
          />
        </CngGridItem>
        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.processedDate}>
          <CngDateTimeField
            name="processedDate"
            label={translatedTextsObject.processedDate}
            disabled={disabled}
          />
        </CngGridItem>
        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.recordStatus}>
          <CngTextField
            name="recordStatus"
            label={translatedTextsObject.recordStatus}
            disabled={disabled}
          />
        </CngGridItem>
        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.type}>
          <CngTextField
            name="type"
            label={translatedTextsObject.type}
            disabled={disabled}
          />
        </CngGridItem>
        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.source}>
          <CngTextField
            name="source"
            label={translatedTextsObject.source}
            disabled={disabled}
          />
        </CngGridItem>
        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.portofstatus}>
          <CngTextField
            name="portofstatus"
            label={translatedTextsObject.portofstatus}
            disabled={disabled}
          />
        </CngGridItem>
        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.description}>
          <CngTextField
            name="description"
            label={translatedTextsObject.description}
            disabled={disabled}
          />
        </CngGridItem>
        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.bookingref}>
          <CngTextField
            name="bookingref"
            label={translatedTextsObject.bookingref}
            disabled={disabled}
          />
        </CngGridItem>
        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.details}>
          <CngTextField
            name="details"
            label={translatedTextsObject.details}
            disabled={disabled}
          />
        </CngGridItem>
        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.party}>
          <CngTextField
            name="party"
            label={translatedTextsObject.party}
            disabled={disabled}
          />
        </CngGridItem>
        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.code}>
          <CngTextField
            name="code"
            label={translatedTextsObject.code}
            disabled={disabled}
          />
        </CngGridItem>
        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.eventdate}>
          <CngTextField
            name="eventdate"
            label={translatedTextsObject.eventdate}
            disabled={disabled}
          />
        </CngGridItem>
        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.eventtime}>
          <CngTextField
            name="eventtime"
            label={translatedTextsObject.eventtime}
            disabled={disabled}
          />
        </CngGridItem>
        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.eventTimezone}>
          <CngTextField
            name="eventTimezone"
            label={translatedTextsObject.eventTimezone}
            disabled={disabled}
          />
        </CngGridItem>
        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.contNo}>
          <CngTextField
            name="contNo"
            label={translatedTextsObject.contNo}
            disabled={disabled}
          />
        </CngGridItem>
        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.bLNum}>
          <CngTextField
            name="bLNum"
            label={translatedTextsObject.bLNum}
            disabled={disabled}
          />
        </CngGridItem>
        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.eventDetails}>
          <CngTextField
            name="eventDetails"
            label={translatedTextsObject.eventDetails}
            disabled={disabled}
          />
        </CngGridItem>
        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.vesselName}>
          <CngTextField
            name="vesselName"
            label={translatedTextsObject.vesselName}
            disabled={disabled}
          />
        </CngGridItem>
        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.voyageNum}>
          <CngTextField
            name="voyageNum"
            label={translatedTextsObject.voyageNum}
            disabled={disabled}
          />
        </CngGridItem>
        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.voyageNo}>
          <CngTextField
            name="voyageNo"
            label={translatedTextsObject.voyageNo}
            disabled={disabled}
          />
        </CngGridItem>
        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.remarks}>
          <CngTextField
            name="remarks"
            label={translatedTextsObject.remarks}
            disabled={disabled}
          />
        </CngGridItem>
      </Grid>
	)
}

function toClientDataFormat(serverData) {
    let localData = DataFlattener.parse(serverData);
    localData.processedDate = DateTimeFormatter.toClientDateTime(
        localData.processedDate,
        'YYYY-MM-DD HH:mm:ss'
    );
    return localData;
}

function toServerDataFormat(localData) {
    localData.processedDate = DateTimeFormatter.toServerDateTime(
        localData.processedDate,
        'YYYY-MM-DD HH:mm:ss'
    );
    return DataFlattener.unflatten(localData);
}

const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default FormProperties
