import React from 'react'
import Namespace from 'src/constants/locale/Namespace'
import BatchSftpProcessKeys from 'src/constants/locale/key/BatchSftpProcess'
import { Grid } from '@material-ui/core'
import {
	components,
    DataFlattener,
    DateTimeFormatter,
} from 'cng-web-lib'
import makeValidationSchema from './BatchSftpProcessMakeValidationSchema'
import { useTranslation } from 'cng-web-lib'
import moment from 'moment'

const {
    form: {
        field: {
            CngTextField,
            CngDateTimeField,
        },
    },
    CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  id: 0,
  processName: "",
  processNode: "",
  nextTriggerDate: "",
  startProcessDate: "",
  endProcessDate: "",
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, showNotification, shouldHideMap }) {
  const { translate } = useTranslation(Namespace.BATCH_SFTP_PROCESS)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let batchSftpProcess = translate(
      Namespace.BATCH_SFTP_PROCESS,
      BatchSftpProcessKeys.TITLE
    )
    let id = translate(
      Namespace.BATCH_SFTP_PROCESS,
      BatchSftpProcessKeys.ID
    )
    let processName = translate(
      Namespace.BATCH_SFTP_PROCESS,
      BatchSftpProcessKeys.PROCESS_NAME
    )
    let processNode = translate(
      Namespace.BATCH_SFTP_PROCESS,
      BatchSftpProcessKeys.PROCESS_NODE
    )
    let nextTriggerDate = translate(
      Namespace.BATCH_SFTP_PROCESS,
      BatchSftpProcessKeys.NEXT_TRIGGER_DATE
    )
    let startProcessDate = translate(
      Namespace.BATCH_SFTP_PROCESS,
      BatchSftpProcessKeys.START_PROCESS_DATE
    )
    let endProcessDate = translate(
      Namespace.BATCH_SFTP_PROCESS,
      BatchSftpProcessKeys.END_PROCESS_DATE
    )

    return {
      batchSftpProcess,
      id,
      processName,
      processNode,
      nextTriggerDate,
      startProcessDate,
      endProcessDate
    }
  }

	return (
        <Grid container spacing={3}>
        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.id}>
          <CngTextField
            name="id"
            type="number"
            label={translatedTextsObject.id}
            disabled={disabled}
          />
        </CngGridItem>
        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.processName}>
          <CngTextField
            name="processName"
            label={translatedTextsObject.processName}
            disabled={disabled}
          />
        </CngGridItem>
        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.processNode}>
          <CngTextField
            name="processNode"
            label={translatedTextsObject.processNode}
            disabled={disabled}
          />
        </CngGridItem>
        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.nextTriggerDate}>
          <CngDateTimeField
            name="nextTriggerDate"
            label={translatedTextsObject.nextTriggerDate}
            disabled={disabled}
          />
        </CngGridItem>
        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.startProcessDate}>
          <CngDateTimeField
            name="startProcessDate"
            label={translatedTextsObject.startProcessDate}
            disabled={disabled}
          />
        </CngGridItem>
        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.endProcessDate}>
          <CngDateTimeField
            name="endProcessDate"
            label={translatedTextsObject.endProcessDate}
            disabled={disabled}
          />
        </CngGridItem>
      </Grid>
	)
}

function toClientDataFormat(serverData) {
  let localData = DataFlattener.parse(serverData);
  localData.nextTriggerDate = DateTimeFormatter.toClientDate(
    localData.nextTriggerDate,
        'YYYY-MM-DD HH:mm:ss'
  );
  localData.startProcessDate = DateTimeFormatter.toClientDate(
    localData.startProcessDate,
        'YYYY-MM-DD HH:mm:ss'
  );
  localData.endProcessDate = DateTimeFormatter.toClientDate(
    localData.endProcessDate,
        'YYYY-MM-DD HH:mm:ss'
  );
  return localData;
}

function toServerDataFormat(localData) {
  localData.nextTriggerDate = DateTimeFormatter.toServerDate(
    localData.nextTriggerDate,
        'YYYY-MM-DD HH:mm:ss'
  );
  localData.startProcessDate = DateTimeFormatter.toServerDate(
    localData.startProcessDate,
        'YYYY-MM-DD HH:mm:ss'
  );
  localData.endProcessDate = DateTimeFormatter.toServerDate(
    localData.endProcessDate,
        'YYYY-MM-DD HH:mm:ss'
  );
  return DataFlattener.unflatten(localData);
}

const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default FormProperties
