import { Card, CardContent, Grid } from '@material-ui/core'
import {
  components,
  constants,
  useTranslation
} from 'cng-web-lib'

import CodeMappingApiUrls from 'src/apiUrls/CodeMappingApiUrls'
import React from 'react'
import CodeMappingKeys from 'src/constants/locale/key/CodeMapping'
import Namespace from 'src/constants/locale/Namespace'

const {
  card: { CngSimpleCardHeader },
  table: {
    CngCrudTable,
    useDefaultNotification,
    useFetchCodeMaintenanceLookup
  }
} = components

const {
  locale: {
    key: { UiComponentKeys }
  }
} = constants

const {
  filter: { EQUAL }
} = constants

function TablePage(props) {
  const {
    location: { pathname },
    showNotification
  } = props

  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup();
  const notification = useDefaultNotification(showNotification)
  const { translate } = useTranslation([
    Namespace.UI_COMPONENT,
    Namespace.CODE_MAPPING
  ])
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let codeMapping = translate(
      Namespace.CODE_MAPPING,
      CodeMappingKeys.TITLE
    )
    let tableTitle = translate(
      Namespace.UI_COMPONENT,
      UiComponentKeys.Table.TITLE,
      {
        nameTitleised: codeMapping
      }
    )
    let platform = translate(
      Namespace.CODE_MAPPING,
      CodeMappingKeys.PLATFORM
    )
    let type = translate(
      Namespace.CODE_MAPPING,
      CodeMappingKeys.TYPE
    )
    let calistaCode = translate(
      Namespace.CODE_MAPPING,
      CodeMappingKeys.CALISTA_CODE
    )
    let ediCode = translate(
      Namespace.CODE_MAPPING,
      CodeMappingKeys.EDI_CODE
    )
    let codeDesc = translate(
      Namespace.CODE_MAPPING,
      CodeMappingKeys.CODE_DESC
    )

    return {
      codeMapping,
      tableTitle,
      platform,
      type,
      calistaCode,
      ediCode,
      codeDesc
    }
  }

  const columns = [
    {
      field: "platform",
      title: translatedTextsObject.platform,
      customLookup: () => {
        return fetchCodeMaintenanceLookup(
          constants.CodeMaintenanceType.CODE_MASTER,
          (error) => console.error(error),
          [{ field: 'codeType', operator: EQUAL, value: 'EDI_PLATFORM_CODE' }]
        )
      }
    },
    {
      field: "type",
      title: translatedTextsObject.type,
      customLookup: () => {
        return fetchCodeMaintenanceLookup(
          constants.CodeMaintenanceType.CODE_MASTER,
          (error) => console.error(error),
          [{ field: 'codeType', operator: EQUAL, value: 'EDI_CODE_TYPE' }]
        )
      }
    },
    {
      field: "calistaCode",
      title: translatedTextsObject.calistaCode,
    },
    {
      field: "ediCode",
      title: translatedTextsObject.ediCode,
    },
    {
      field: "codeDesc",
      title: translatedTextsObject.codeDesc,
    }
  ]

  return (
    <Card>
      <CngSimpleCardHeader title={translatedTextsObject.tableTitle} />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CngCrudTable
              {...props}
              fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
              addRoute={`${pathname}/add`}
              columns={columns}
              del={{ url: CodeMappingApiUrls.DELETE }}
              editRoute={`${pathname}/edit`}
              exportData={{ url: CodeMappingApiUrls.EXPORT }}
              fetch={{ url: CodeMappingApiUrls.GET }}
              idAccessor="id"
              notification={notification}
              //tableConfigurationCode="REPLACE_WITH_CORE_TABLE_CONFIG_CODE"
              viewRoute={`${pathname}/view`}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default TablePage
