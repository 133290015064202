import BatchMilestoneApiUrls from 'src/apiUrls/BatchMilestoneApiUrls'
import BatchMilestoneFormProperties from './BatchMilestoneFormProperties'
import React from 'react'
import { components } from 'cng-web-lib'

const {
    form: { CngAddForm }
} = components

function BatchMilestoneAddForm({ history, showNotification, onPostSubmitSuccess }) {
    return (
        <CngAddForm
            fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
            history={history}
            showNotification={showNotification}
            bodySection={
                <BatchMilestoneFormProperties.Fields
                    showNotification={showNotification}
                />
            }
            formikProps={BatchMilestoneFormProperties.formikProps}
            toClientDataFormat={BatchMilestoneFormProperties.toClientDataFormat}
            toServerDataFormat={BatchMilestoneFormProperties.toServerDataFormat}
            create={{
                url: BatchMilestoneApiUrls.POST,
                onPostSubmitSuccess: onPostSubmitSuccess
            }}
        />
    )
}

export default BatchMilestoneAddForm
