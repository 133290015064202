import BatchSftpProcessApiUrls from 'src/apiUrls/BatchSftpProcessApiUrls'
import BatchSftpProcessFormProperties from './BatchSftpProcessFormProperties'
import React from 'react'
import { components } from 'cng-web-lib'

const {
    form: { CngAddForm }
} = components

function BatchSftpProcessAddForm({ history, showNotification, onPostSubmitSuccess }) {
    return (
        <CngAddForm
            fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
            history={history}
            showNotification={showNotification}
            bodySection={
                <BatchSftpProcessFormProperties.Fields
                    showNotification={showNotification}
                />
            }
            formikProps={BatchSftpProcessFormProperties.formikProps}
            toClientDataFormat={BatchSftpProcessFormProperties.toClientDataFormat}
            toServerDataFormat={BatchSftpProcessFormProperties.toServerDataFormat}
            create={{
                url: BatchSftpProcessApiUrls.POST,
                onPostSubmitSuccess: onPostSubmitSuccess
            }}
        />
    )
}

export default BatchSftpProcessAddForm
