import { Yup } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import {
  constants,
} from 'cng-web-lib'

const {
  locale: {
    key: {
      CommonValidationMessageKeys,
    },
  },
} = constants

function BatchMilestoneMakeValidationSchema(translate) {
  let requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )
  let dateTypeErrorMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.DATE_TYPE_ERROR
  )

  return Yup.object({
    id: Yup.string(),
    processedDate: Yup.date().validFormat("YYYY-MM-DD HH:mm:ss").typeError("Invalid date format. Only \"YYYY-MM-DD HH:mm:ss\" format is valid.").required(requiredMessage),
    recordStatus: Yup.string(),
    type: Yup.string(),
    source: Yup.string(),
    portofstatus: Yup.string(),
    description: Yup.string(),
    bookingref: Yup.string(),
    details: Yup.string(),
    party: Yup.string(),
    code: Yup.string(),
    eventdate: Yup.string(),
    eventtime: Yup.string(),
    eventTimezone: Yup.string(),
    contNo: Yup.string(),
    bLNum: Yup.string(),
    eventDetails: Yup.string(),
    vesselName: Yup.string(),
    voyageNum: Yup.string(),
    voyageNo: Yup.string(),
    remarks: Yup.string(),
  })
}

export default BatchMilestoneMakeValidationSchema
