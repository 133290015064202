import BatchDataConfigApiUrls from 'src/apiUrls/BatchDataConfigApiUrls'
import BatchDataConfigFormProperties from './BatchDataConfigFormProperties'
import React from 'react'
import { components } from 'cng-web-lib'

const {
    form: { CngAddForm }
} = components

function BatchDataConfigAddForm({
    history,
    showNotification,
    onPostSubmitSuccess
}) {
    return (
        <CngAddForm
            fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
            history={history}
            showNotification={showNotification}
            bodySection={
                <BatchDataConfigFormProperties.Fields
                    showNotification={showNotification}
                />
            }
            formikProps={BatchDataConfigFormProperties.formikProps}
            toClientDataFormat={BatchDataConfigFormProperties.toClientDataFormat}
            toServerDataFormat={BatchDataConfigFormProperties.toServerDataFormat}
            create={{
                url: BatchDataConfigApiUrls.POST,
                onPostSubmitSuccess: onPostSubmitSuccess
            }}
        />
    )
}

export default BatchDataConfigAddForm
