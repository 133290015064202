import { Card, CardContent, Grid } from '@material-ui/core'
import {
  components,
  constants,
  useTranslation
} from 'cng-web-lib'

import BatchDataConfigApiUrls from 'src/apiUrls/BatchDataConfigApiUrls'
import React from 'react'
import BatchDataConfigKeys from 'src/constants/locale/key/BatchDataConfig'
import Namespace from 'src/constants/locale/Namespace'

const {
  card: { CngSimpleCardHeader },
  table: {
    CngCrudTable,
    useDefaultNotification
  }
} = components

const {
  locale: {
    key: { UiComponentKeys }
  }
} = constants

function TablePage(props) {
  const {
    location: { pathname },
    showNotification
  } = props

  const notification = useDefaultNotification(showNotification)
  const { translate } = useTranslation([
    Namespace.UI_COMPONENT,
    Namespace.BATCH_DATA_CONFIG
  ])
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let batchDataConfig = translate(
      Namespace.BATCH_DATA_CONFIG,
      BatchDataConfigKeys.TITLE
    )
    let tableTitle = translate(
      Namespace.UI_COMPONENT,
      UiComponentKeys.Table.TITLE,
      {
        nameTitleised: batchDataConfig
      }
    )
    let configCode = translate(
      Namespace.BATCH_DATA_CONFIG,
      BatchDataConfigKeys.CONFIG_CODE
    )
    let configValue1 = translate(
      Namespace.BATCH_DATA_CONFIG,
      BatchDataConfigKeys.CONFIG_VALUE1
    )
    let configValu2 = translate(
      Namespace.BATCH_DATA_CONFIG,
      BatchDataConfigKeys.CONFIG_VALU2
    )
    let configValu3 = translate(
      Namespace.BATCH_DATA_CONFIG,
      BatchDataConfigKeys.CONFIG_VALU3
    )
    let configDescription = translate(
      Namespace.BATCH_DATA_CONFIG,
      BatchDataConfigKeys.CONFIG_DESCRIPTION
    )

    return {
      batchDataConfig,
      tableTitle,
      configCode,
      configValue1,
      configValu2,
      configValu3,
      configDescription
    }
  }

  const columns = [
    {
      field: "configCode",
      title: translatedTextsObject.configCode,
    },
    {
      field: "configValue1",
      title: translatedTextsObject.configValue1,
    },
    {
      field: "configValu2",
      title: translatedTextsObject.configValu2,
    },
    {
      field: "configValu3",
      title: translatedTextsObject.configValu3,
    },
    {
      field: "configDescription",
      title: translatedTextsObject.configDescription,
    }
  ]

  return (
    <Card>
      <CngSimpleCardHeader title={translatedTextsObject.tableTitle} />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CngCrudTable
              {...props}
              fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
              addRoute={`${pathname}/add`}
              columns={columns}
              del={{ url: BatchDataConfigApiUrls.DELETE }}
              editRoute={`${pathname}/edit`}
              exportData={{ url: BatchDataConfigApiUrls.EXPORT }}
              fetch={{ url: BatchDataConfigApiUrls.GET }}
              idAccessor="id"
              notification={notification}
              //tableConfigurationCode="REPLACE_WITH_CORE_TABLE_CONFIG_CODE"
              viewRoute={`${pathname}/view`}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default TablePage
