import CodeMappingFormProperties from './CodeMappingFormProperties'
import CodeMappingApiUrls from 'src/apiUrls/CodeMappingApiUrls'
import { components } from 'cng-web-lib'
import React from 'react'

const {
    form: { CngAddForm }
} = components

function CodeMappingAddForm({
    history,
    showNotification,
    onPostSubmitSuccess
}) {
    return (
        <CngAddForm
            fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
            history={history}
            showNotification={showNotification}
            bodySection={
                <CodeMappingFormProperties.Fields
                    showNotification={showNotification}
                />
            }
            formikProps={CodeMappingFormProperties.formikProps}
            toClientDataFormat={CodeMappingFormProperties.toClientDataFormat}
            toServerDataFormat={CodeMappingFormProperties.toServerDataFormat}
            create={{
                url: CodeMappingApiUrls.POST,
                onPostSubmitSuccess: onPostSubmitSuccess
            }}
        />
    )
}

export default CodeMappingAddForm
