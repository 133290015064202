import BatchBookingRequestApiUrls from 'src/apiUrls/BatchBookingRequestApiUrls'
import BatchBookingRequestFormProperties from './BatchBookingRequestFormProperties'
import React from 'react'
import { components } from 'cng-web-lib'

const {
  form: { CngViewForm }
} = components

function BatchBookingRequestViewForm({
  showNotification,
  id,
  onFetchPreSuccess = () => { 
    //do nothing 
  }
}) {
  return (
    <CngViewForm
      fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
      showNotification={showNotification}
      bodySection={
        <BatchBookingRequestFormProperties.Fields
          disabled={true}
          showNotification={showNotification}
        />
      }
      formikProps={BatchBookingRequestFormProperties.formikProps}
      toClientDataFormat={BatchBookingRequestFormProperties.toClientDataFormat}
      toServerDataFormat={BatchBookingRequestFormProperties.toServerDataFormat}
      fetch={{
        id: id,
        url: BatchBookingRequestApiUrls.GET
      }}
    />
  )
}

export default BatchBookingRequestViewForm
