import { Card, CardContent, Grid } from '@material-ui/core'
import {
  components,
  constants,
  useTranslation
} from 'cng-web-lib'

import BatchBookingRequestApiUrls from 'src/apiUrls/BatchBookingRequestApiUrls'
import React from 'react'
import BatchBookingRequestKeys from 'src/constants/locale/key/BatchBookingRequest'
import Namespace from 'src/constants/locale/Namespace'

const {
  card: { CngSimpleCardHeader },
  table: {
    CngCrudTable,
    DateTimeRangeFilter: CngDateTimeRangeFilter,
    useDefaultNotification
  }
} = components

const {
  locale: {
    key: { UiComponentKeys }
  }
} = constants

function TablePage(props) {
  const {
    location: { pathname },
    showNotification
  } = props

  const notification = useDefaultNotification(showNotification)
  const { translate } = useTranslation([
    Namespace.UI_COMPONENT,
    Namespace.BATCH_BOOKING_REQUEST
  ])
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let batchBookingRequest = translate(
      Namespace.BATCH_BOOKING_REQUEST,
      BatchBookingRequestKeys.TITLE
    )
    let tableTitle = translate(
      Namespace.UI_COMPONENT,
      UiComponentKeys.Table.TITLE,
      {
        nameTitleised: batchBookingRequest
      }
    )
    let id = translate(
      Namespace.BATCH_BOOKING_REQUEST,
      BatchBookingRequestKeys.ID
    )
    let party = translate(
      Namespace.BATCH_BOOKING_REQUEST,
      BatchBookingRequestKeys.PARTY
    )
    let senderCode = translate(
      Namespace.BATCH_BOOKING_REQUEST,
      BatchBookingRequestKeys.SENDER_CODE
    )
    let senderQualifier = translate(
      Namespace.BATCH_BOOKING_REQUEST,
      BatchBookingRequestKeys.SENDER_QUALIFIER
    )
    let receiverQualifier = translate(
      Namespace.BATCH_BOOKING_REQUEST,
      BatchBookingRequestKeys.RECEIVER_QUALIFIER
    )
    let messageReferenceNo = translate(
      Namespace.BATCH_BOOKING_REQUEST,
      BatchBookingRequestKeys.MESSAGE_REFERENCE_NO
    )
    let messageType = translate(
      Namespace.BATCH_BOOKING_REQUEST,
      BatchBookingRequestKeys.MESSAGE_TYPE
    )
    let carrierName = translate(
      Namespace.BATCH_BOOKING_REQUEST,
      BatchBookingRequestKeys.CARRIER_NAME
    )
    let messageFunction = translate(
      Namespace.BATCH_BOOKING_REQUEST,
      BatchBookingRequestKeys.MESSAGE_FUNCTION
    )
    let documentMsgNo = translate(
      Namespace.BATCH_BOOKING_REQUEST,
      BatchBookingRequestKeys.DOCUMENT_MSG_NO
    )
    let documentIssuedOnDate = translate(
      Namespace.BATCH_BOOKING_REQUEST,
      BatchBookingRequestKeys.DOCUMENT_ISSUED_ON_DATE
    )
    let documentIssuedOnTime = translate(
      Namespace.BATCH_BOOKING_REQUEST,
      BatchBookingRequestKeys.DOCUMENT_ISSUED_ON_TIME
    )
    let acknowledgementIndicator = translate(
      Namespace.BATCH_BOOKING_REQUEST,
      BatchBookingRequestKeys.ACKNOWLEDGEMENT_INDICATOR
    )
    let bookingReferenceNo = translate(
      Namespace.BATCH_BOOKING_REQUEST,
      BatchBookingRequestKeys.BOOKING_REFERENCE_NO
    )
    let orderNumber = translate(
      Namespace.BATCH_BOOKING_REQUEST,
      BatchBookingRequestKeys.ORDER_NUMBER
    )
    let shipperRemark = translate(
      Namespace.BATCH_BOOKING_REQUEST,
      BatchBookingRequestKeys.SHIPPER_REMARK
    )
    let bookingShipmentType = translate(
      Namespace.BATCH_BOOKING_REQUEST,
      BatchBookingRequestKeys.BOOKING_SHIPMENT_TYPE
    )
    let stageCode = translate(
      Namespace.BATCH_BOOKING_REQUEST,
      BatchBookingRequestKeys.STAGE_CODE
    )
    let consignorReferenceNo = translate(
      Namespace.BATCH_BOOKING_REQUEST,
      BatchBookingRequestKeys.CONSIGNOR_REFERENCE_NO
    )
    let voyageNo = translate(
      Namespace.BATCH_BOOKING_REQUEST,
      BatchBookingRequestKeys.VOYAGE_NO
    )
    let vesselName = translate(
      Namespace.BATCH_BOOKING_REQUEST,
      BatchBookingRequestKeys.VESSEL_NAME
    )
    let portOfLoadingPortCode = translate(
      Namespace.BATCH_BOOKING_REQUEST,
      BatchBookingRequestKeys.PORT_OF_LOADING_PORT_CODE
    )
    let portOfLoeadingPortCodeList = translate(
      Namespace.BATCH_BOOKING_REQUEST,
      BatchBookingRequestKeys.PORT_OF_LOEADING_PORT_CODE_LIST
    )
    let portOfLoadingDateCode = translate(
      Namespace.BATCH_BOOKING_REQUEST,
      BatchBookingRequestKeys.PORT_OF_LOADING_DATE_CODE
    )
    let portOfLoadingDate = translate(
      Namespace.BATCH_BOOKING_REQUEST,
      BatchBookingRequestKeys.PORT_OF_LOADING_DATE
    )
    let portOfLoadingTime = translate(
      Namespace.BATCH_BOOKING_REQUEST,
      BatchBookingRequestKeys.PORT_OF_LOADING_TIME
    )
    let portOfDischargePortCode = translate(
      Namespace.BATCH_BOOKING_REQUEST,
      BatchBookingRequestKeys.PORT_OF_DISCHARGE_PORT_CODE
    )
    let portOfDischargePortCodeList = translate(
      Namespace.BATCH_BOOKING_REQUEST,
      BatchBookingRequestKeys.PORT_OF_DISCHARGE_PORT_CODE_LIST
    )
    let placeOfReceiptPortName = translate(
      Namespace.BATCH_BOOKING_REQUEST,
      BatchBookingRequestKeys.PLACE_OF_RECEIPT_PORT_NAME
    )
    let placeOfReceiptDateCode = translate(
      Namespace.BATCH_BOOKING_REQUEST,
      BatchBookingRequestKeys.PLACE_OF_RECEIPT_DATE_CODE
    )
    let placeOfReceiptDate = translate(
      Namespace.BATCH_BOOKING_REQUEST,
      BatchBookingRequestKeys.PLACE_OF_RECEIPT_DATE
    )
    let placeOfDeliveryPortName = translate(
      Namespace.BATCH_BOOKING_REQUEST,
      BatchBookingRequestKeys.PLACE_OF_DELIVERY_PORT_NAME
    )
    let placeOfDeliveryDateCode = translate(
      Namespace.BATCH_BOOKING_REQUEST,
      BatchBookingRequestKeys.PLACE_OF_DELIVERY_DATE_CODE
    )
    let placeOfDeliveyDate = translate(
      Namespace.BATCH_BOOKING_REQUEST,
      BatchBookingRequestKeys.PLACE_OF_DELIVEY_DATE
    )
    let consignorName = translate(
      Namespace.BATCH_BOOKING_REQUEST,
      BatchBookingRequestKeys.CONSIGNOR_NAME
    )
    let consigneeName = translate(
      Namespace.BATCH_BOOKING_REQUEST,
      BatchBookingRequestKeys.CONSIGNEE_NAME
    )
    let consigneeAddress = translate(
      Namespace.BATCH_BOOKING_REQUEST,
      BatchBookingRequestKeys.CONSIGNEE_ADDRESS
    )
    let containerTypesAndSizes = translate(
      Namespace.BATCH_BOOKING_REQUEST,
      BatchBookingRequestKeys.CONTAINER_TYPES_AND_SIZES
    )
    let socOrCocIndicator = translate(
      Namespace.BATCH_BOOKING_REQUEST,
      BatchBookingRequestKeys.SOC_OR_COC_INDICATOR
    )
    let fullOrEmptyIndicator = translate(
      Namespace.BATCH_BOOKING_REQUEST,
      BatchBookingRequestKeys.FULL_OR_EMPTY_INDICATOR
    )
    let totalQuantity = translate(
      Namespace.BATCH_BOOKING_REQUEST,
      BatchBookingRequestKeys.TOTAL_QUANTITY
    )
    let bookingId = translate(
      Namespace.BATCH_BOOKING_REQUEST,
      BatchBookingRequestKeys.BOOKING_ID
    )
    let fileType = translate(
      Namespace.BATCH_BOOKING_REQUEST,
      BatchBookingRequestKeys.FILE_TYPE
    )
    let source = translate(
      Namespace.BATCH_BOOKING_REQUEST,
      BatchBookingRequestKeys.SOURCE
    )
    let recordStatus = translate(
      Namespace.BATCH_BOOKING_REQUEST,
      BatchBookingRequestKeys.RECORD_STATUS
    )
    let processedDate = translate(
      Namespace.BATCH_BOOKING_REQUEST,
      BatchBookingRequestKeys.PROCESSED_DATE
    )

    return {
      batchBookingRequest,
      tableTitle,
      id,
      party,
      senderCode,
      senderQualifier,
      receiverQualifier,
      messageReferenceNo,
      messageType,
      carrierName,
      messageFunction,
      documentMsgNo,
      documentIssuedOnDate,
      documentIssuedOnTime,
      acknowledgementIndicator,
      bookingReferenceNo,
      orderNumber,
      shipperRemark,
      bookingShipmentType,
      stageCode,
      consignorReferenceNo,
      voyageNo,
      vesselName,
      portOfLoadingPortCode,
      portOfLoeadingPortCodeList,
      portOfLoadingDateCode,
      portOfLoadingDate,
      portOfLoadingTime,
      portOfDischargePortCode,
      portOfDischargePortCodeList,
      placeOfReceiptPortName,
      placeOfReceiptDateCode,
      placeOfReceiptDate,
      placeOfDeliveryPortName,
      placeOfDeliveryDateCode,
      placeOfDeliveyDate,
      consignorName,
      consigneeName,
      consigneeAddress,
      containerTypesAndSizes,
      socOrCocIndicator,
      fullOrEmptyIndicator,
      totalQuantity,
      bookingId,
      fileType,
      source,
      recordStatus,
      processedDate
    }
  }

  const columns = [
    {
      field: "id",
      title: translatedTextsObject.id,
      type: "numeric",
    },
    {
      field: "party",
      title: translatedTextsObject.party,
    },
    {
      field: "carrierName",
      title: translatedTextsObject.carrierName,
    },
    {
      field: "messageFunction",
      title: translatedTextsObject.messageFunction,
    },
    {
      field: "documentIssuedOnDate",
      title: translatedTextsObject.documentIssuedOnDate,
    },
    {
      field: "consignorReferenceNo",
      title: translatedTextsObject.consignorReferenceNo,
    },
    {
      field: "voyageNo",
      title: translatedTextsObject.voyageNo,
    },
    {
      field: "vesselName",
      title: translatedTextsObject.vesselName,
    },
    {
      field: "portOfLoadingPortCode",
      title: translatedTextsObject.portOfLoadingPortCode,
    },
    {
      field: "portOfDischargePortCode",
      title: translatedTextsObject.portOfDischargePortCode,
    },
    {
      field: "fileType",
      title: translatedTextsObject.fileType,
    },
    {
      field: "source",
      title: translatedTextsObject.source,
    },
    {
      field: "recordStatus",
      title: translatedTextsObject.recordStatus,
    },
    {
      field: "processedDate",
      title: translatedTextsObject.processedDate,
      type: "datetime",
      filterComponent: CngDateTimeRangeFilter,
    }
  ]

  return (
    <Card>
      <CngSimpleCardHeader title={translatedTextsObject.tableTitle} />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CngCrudTable
              {...props}
              fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
              addRoute={`${pathname}/add`}
              columns={columns}
              del={{ url: BatchBookingRequestApiUrls.DELETE }}
              editRoute={`${pathname}/edit`}
              exportData={{ url: BatchBookingRequestApiUrls.EXPORT }}
              fetch={{ url: BatchBookingRequestApiUrls.GET }}
              idAccessor="id"
              notification={notification}
              //tableConfigurationCode="REPLACE_WITH_CORE_TABLE_CONFIG_CODE"
              viewRoute={`${pathname}/view`}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default TablePage
