import BatchBookingRequestApiUrls from 'src/apiUrls/BatchBookingRequestApiUrls'
import BatchBookingRequestFormProperties from './BatchBookingRequestFormProperties'
import React from 'react'
import { components } from 'cng-web-lib'

const {
    form: { CngAddForm }
} = components

function BatchBookingRequestAddForm({ history, showNotification, onPostSubmitSuccess }) {
    return (
        <CngAddForm
            fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
            history={history}
            showNotification={showNotification}
            bodySection={
                <BatchBookingRequestFormProperties.Fields
                    showNotification={showNotification}
                />
            }
            formikProps={BatchBookingRequestFormProperties.formikProps}
            toClientDataFormat={BatchBookingRequestFormProperties.toClientDataFormat}
            toServerDataFormat={BatchBookingRequestFormProperties.toServerDataFormat}
            create={{
                url: BatchBookingRequestApiUrls.POST,
                onPostSubmitSuccess: onPostSubmitSuccess
            }}
        />
    )
}

export default BatchBookingRequestAddForm
