import { Container, Grid, Paper } from '@material-ui/core'
import React, { useState } from 'react'
import { components, useTranslation } from 'cng-web-lib'

import BatchBookingRequestViewForm from './BatchBookingRequestViewForm'
import BatchBookingRequestEditForm from './BatchBookingRequestEditForm'
import { useParams } from 'react-router-dom'
import Namespace from 'src/constants/locale/Namespace'
import BatchBookingRequestKeys from 'src/constants/locale/key/BatchBookingRequest'

const { CngTabs } = components


function EditPage({ showNotification }) {
  const { id } = useParams()
  const { translate } = useTranslation([Namespace.BATCH_BOOKING_REQUEST])
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let batchBookingRequest = translate(
      Namespace.BATCH_BOOKING_REQUEST,
      BatchBookingRequestKeys.TITLE
    )

    return {
      batchBookingRequest
    }
  }

  return (
    <Grid container spacing={3}>
      {/* <Grid item xs={12}>
        <Paper>
          <Container maxWidth='xl'>
            <BatchBookingRequestViewForm
              showNotification={showNotification}
              id={id}
              onFetchPreSuccess={(datum) => {
              }}
            />
          </Container>
        </Paper>
      </Grid> */}
      <Grid item xs={12}>
        <CngTabs
          headerColor='primary'
          tabs={[
            {
              tabName: translatedTextsObject.batchBookingRequest,
              tabContent: (
                <BatchBookingRequestEditForm
                  showNotification={showNotification}
                  id={id}
                />
              )
            },
          ]}
        />
      </Grid>
    </Grid>
  );
}

export default EditPage