import BatchMilestoneApiUrls from 'src/apiUrls/BatchMilestoneApiUrls'
import BatchMilestoneFormProperties from './BatchMilestoneFormProperties'
import React from 'react'
import { components } from 'cng-web-lib'

const {
  form: { CngEditForm }
} = components

function BatchMilestoneEditForm({
  showNotification,
  id,
  onFetchPreSuccess = () => {
    //do nothing
   }
}) {
  return (
    <CngEditForm
      fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
      showNotification={showNotification}
      bodySection={
        <BatchMilestoneFormProperties.Fields
          disabled={false}
          showNotification={showNotification}
        />
      }
      formikProps={BatchMilestoneFormProperties.formikProps}
      toClientDataFormat={BatchMilestoneFormProperties.toClientDataFormat}
      toServerDataFormat={BatchMilestoneFormProperties.toServerDataFormat}
      fetch={{
        url: BatchMilestoneApiUrls.GET,
        onPreSuccess: onFetchPreSuccess
      }}
      update={{
        url: BatchMilestoneApiUrls.PUT
      }}
      id={id}
    />
  )
}

export default BatchMilestoneEditForm
