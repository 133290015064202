import { Card, CardContent, Grid } from '@material-ui/core'
import {
  components,
  constants,
  useTranslation
} from 'cng-web-lib'

import BatchSftpProcessApiUrls from 'src/apiUrls/BatchSftpProcessApiUrls'
import React from 'react'
import BatchSftpProcessKeys from 'src/constants/locale/key/BatchSftpProcess'
import Namespace from 'src/constants/locale/Namespace'

const {
  card: { CngSimpleCardHeader },
  table: {
    CngCrudTable,
    DateTimeRangeFilter: CngDateTimeRangeFilter,
    useDefaultNotification
  }
} = components

const {
  locale: {
    key: { UiComponentKeys }
  }
} = constants

function TablePage(props) {
  const {
    location: { pathname },
    showNotification
  } = props

  const notification = useDefaultNotification(showNotification)
  const { translate } = useTranslation([
    Namespace.UI_COMPONENT,
    Namespace.BATCH_SFTP_PROCESS
  ])
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let batchSftpProcess = translate(
      Namespace.BATCH_SFTP_PROCESS,
      BatchSftpProcessKeys.TITLE
    )
    let tableTitle = translate(
      Namespace.UI_COMPONENT,
      UiComponentKeys.Table.TITLE,
      {
        nameTitleised: batchSftpProcess
      }
    )
    let id = translate(
      Namespace.BATCH_SFTP_PROCESS,
      BatchSftpProcessKeys.ID
    )
    let processName = translate(
      Namespace.BATCH_SFTP_PROCESS,
      BatchSftpProcessKeys.PROCESS_NAME
    )
    let processNode = translate(
      Namespace.BATCH_SFTP_PROCESS,
      BatchSftpProcessKeys.PROCESS_NODE
    )
    let nextTriggerDate = translate(
      Namespace.BATCH_SFTP_PROCESS,
      BatchSftpProcessKeys.NEXT_TRIGGER_DATE
    )
    let startProcessDate = translate(
      Namespace.BATCH_SFTP_PROCESS,
      BatchSftpProcessKeys.START_PROCESS_DATE
    )
    let endProcessDate = translate(
      Namespace.BATCH_SFTP_PROCESS,
      BatchSftpProcessKeys.END_PROCESS_DATE
    )

    return {
      batchSftpProcess,
      tableTitle,
      id,
      processName,
      processNode,
      nextTriggerDate,
      startProcessDate,
      endProcessDate
    }
  }

  const columns = [
    {
      field: "id",
      title: translatedTextsObject.id,
      type: "numeric",
    },
    {
      field: "processName",
      title: translatedTextsObject.processName,
    },
    {
      field: "processNode",
      title: translatedTextsObject.processNode,
    },
    {
      field: "nextTriggerDate",
      title: translatedTextsObject.nextTriggerDate,
      type: "datetime",
      filterComponent: CngDateTimeRangeFilter,
    },
    {
      field: "startProcessDate",
      title: translatedTextsObject.startProcessDate,
      type: "datetime",
      filterComponent: CngDateTimeRangeFilter,
    },
    {
      field: "endProcessDate",
      title: translatedTextsObject.endProcessDate,
      type: "datetime",
      filterComponent: CngDateTimeRangeFilter,
    }
  ]

  return (
    <Card>
      <CngSimpleCardHeader title={translatedTextsObject.tableTitle} />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CngCrudTable
              {...props}
              fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
              addRoute={`${pathname}/add`}
              columns={columns}
              del={{ url: BatchSftpProcessApiUrls.DELETE }}
              editRoute={`${pathname}/edit`}
              exportData={{ url: BatchSftpProcessApiUrls.EXPORT }}
              fetch={{ url: BatchSftpProcessApiUrls.GET }}
              idAccessor="id"
              notification={notification}
              //tableConfigurationCode="REPLACE_WITH_CORE_TABLE_CONFIG_CODE"
              viewRoute={`${pathname}/view`}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default TablePage
