import { Container, Grid, Paper } from '@material-ui/core'
import React, { useState } from 'react'
import { components, useTranslation } from 'cng-web-lib'

import BatchMilestoneViewForm from './BatchMilestoneViewForm'
import BatchMilestoneAddForm from './BatchMilestoneAddForm'
import BatchMilestoneEditForm from './BatchMilestoneEditForm'
import Namespace from 'src/constants/locale/Namespace'
import BatchMilestoneKeys from 'src/constants/locale/key/BatchMilestone'
import {
  constants,
} from 'cng-web-lib'

const {
  locale: {
    key: {
      UiComponentKeys,
    },
  },
} = constants

const { CngTabs } = components

function AddPage({ history, showNotification }) {
  const [lockedOnFirstTab, setLockedOnFirstTab] = useState(true)
  const [id, setId] = useState('')
  const { translate } = useTranslation([
    Namespace.BATCH_MILESTONE,
    Namespace.UI_COMPONENT
  ])
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let batchMilestone = translate(
      Namespace.BATCH_MILESTONE,
      BatchMilestoneKeys.TITLE
    )
    let tabLockedMessage = translate(
      Namespace.UI_COMPONENT,
      UiComponentKeys.Tabs.PLEASE_SUBMIT_FIRST,
      {
        title: batchMilestone
      }
    )

    return {
      batchMilestone,
      tabLockedMessage
    }
  }

  function makeBatchMilestoneForm() {
    if (id === '') {
      return (
        <BatchMilestoneAddForm
          history={history}
          showNotification={showNotification}
          onPostSubmitSuccess={(datum) => {
            setId(datum.id)
            setLockedOnFirstTab(false)
          }}
        />
      )
    } else {
      return (
        <BatchMilestoneEditForm
          showNotification={showNotification}
          id={id}
        />
      )
    }
  }

  return (
    <Grid container spacing={3}>
      {}
      <Grid item xs={12}>
        <CngTabs
          headerColor='primary'
          tabs={[
            {
              tabName: translatedTextsObject.batchMilestone,
              tabContent: makeBatchMilestoneForm()
            },
          ]}
          lockedOnFirstTab={lockedOnFirstTab}
          tabLockedMessage={translatedTextsObject.tabLockedMessage}
        />
      </Grid>
    </Grid>
  )
}

export default AddPage
