import { Container, Grid, Paper } from '@material-ui/core'
import React, { useState } from 'react'
import { components, useTranslation } from 'cng-web-lib'

import BatchBookingRequestViewForm from './BatchBookingRequestViewForm'
import BatchBookingRequestAddForm from './BatchBookingRequestAddForm'
import BatchBookingRequestEditForm from './BatchBookingRequestEditForm'
import Namespace from 'src/constants/locale/Namespace'
import BatchBookingRequestKeys from 'src/constants/locale/key/BatchBookingRequest'
import {
  constants,
} from 'cng-web-lib'

const {
  locale: {
    key: {
      UiComponentKeys,
    },
  },
} = constants

const { CngTabs } = components

function AddPage({ history, showNotification }) {
  const [lockedOnFirstTab, setLockedOnFirstTab] = useState(true)
  const [batchBookingRequestId, setBatchBookingRequestId] = useState('')
  const { translate } = useTranslation([
    Namespace.BATCH_BOOKING_REQUEST,
    Namespace.UI_COMPONENT
  ])
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let batchBookingRequest = translate(
      Namespace.BATCH_BOOKING_REQUEST,
      BatchBookingRequestKeys.TITLE
    )
    let tabLockedMessage = translate(
      Namespace.UI_COMPONENT,
      UiComponentKeys.Tabs.PLEASE_SUBMIT_FIRST,
      {
        title: batchBookingRequest
      }
    )

    return {
      batchBookingRequest,
      tabLockedMessage
    }
  }

  function makeBatchBookingRequestForm() {
    if (batchBookingRequestId === '') {
      return (
        <BatchBookingRequestAddForm
          history={history}
          showNotification={showNotification}
          onPostSubmitSuccess={(datum) => {
            setBatchBookingRequestId(datum.id)
            setLockedOnFirstTab(false)
          }}
        />
      )
    } else {
      return (
        <BatchBookingRequestEditForm
          showNotification={showNotification}
          id={batchBookingRequestId}
        />
      )
    }
  }

  return (
    <Grid container spacing={3}>
      {/* <Grid item xs={12}>
        <Paper>
          <Container maxWidth='xl'>
            <BatchBookingRequestViewForm
              showNotification={showNotification}
              id={batchBookingRequestId}
            />
          </Container>
        </Paper>
      </Grid> */}
      <Grid item xs={12}>
        <CngTabs
          headerColor='primary'
          tabs={[
            {
              tabName: translatedTextsObject.batchBookingRequest,
              tabContent: makeBatchBookingRequestForm()
            },
          ]}
          lockedOnFirstTab={lockedOnFirstTab}
          tabLockedMessage={translatedTextsObject.tabLockedMessage}
        />
      </Grid>
    </Grid>
  )
}

export default AddPage