import CodeMappingFormProperties from './CodeMappingFormProperties'
import CodeMappingApiUrls from 'src/apiUrls/CodeMappingApiUrls'
import { components } from 'cng-web-lib'
import React from 'react'

const {
    form: { CngEditForm }
} = components

function CodeMappingEditForm({
    showNotification,
    id,
    onFetchPreSuccess = () => {
        //do nothing
     }
}) {
    return (
        <CngEditForm
            fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
            showNotification={showNotification}
            bodySection={
                <CodeMappingFormProperties.Fields
                    showNotification={showNotification}
                />
            }
            formikProps={CodeMappingFormProperties.formikProps}
            toClientDataFormat={CodeMappingFormProperties.toClientDataFormat}
            toServerDataFormat={CodeMappingFormProperties.toServerDataFormat}
            fetch={{
                url: CodeMappingApiUrls.GET,
                onPreSuccess: onFetchPreSuccess
            }}
            update={{
                url: CodeMappingApiUrls.PUT
            }}
            id={id}
        />
    )
}

export default CodeMappingEditForm
