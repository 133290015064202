import BatchSftpProcessApiUrls from 'src/apiUrls/BatchSftpProcessApiUrls'
import BatchSftpProcessFormProperties from './BatchSftpProcessFormProperties'
import React from 'react'
import { components } from 'cng-web-lib'

const {
  form: { CngEditForm }
} = components

function BatchSftpProcessEditForm({
  showNotification,
  id,
  onFetchPreSuccess = () => {
    //do nothing
   }
}) {
  return (
    <CngEditForm
      fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
      showNotification={showNotification}
      bodySection={
        <BatchSftpProcessFormProperties.Fields
          disabled={false}
          showNotification={showNotification}
        />
      }
      formikProps={BatchSftpProcessFormProperties.formikProps}
      toClientDataFormat={BatchSftpProcessFormProperties.toClientDataFormat}
      toServerDataFormat={BatchSftpProcessFormProperties.toServerDataFormat}
      fetch={{
        url: BatchSftpProcessApiUrls.GET,
        onPreSuccess: onFetchPreSuccess
      }}
      update={{
        url: BatchSftpProcessApiUrls.PUT
      }}
      id={id}
    />
  )
}

export default BatchSftpProcessEditForm
