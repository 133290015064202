import BatchMilestoneApiUrls from 'src/apiUrls/BatchMilestoneApiUrls'
import BatchMilestoneFormProperties from './BatchMilestoneFormProperties'
import React from 'react'
import { components } from 'cng-web-lib'

const {
  form: { CngViewForm }
} = components

function BatchMilestoneViewForm({
  showNotification,
  id,
  onFetchPreSuccess = () => {
    //do nothing
   }
}) {
  return (
    <CngViewForm
      fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
      showNotification={showNotification}
      bodySection={
        <BatchMilestoneFormProperties.Fields
          disabled={true}
          showNotification={showNotification}
        />
      }
      formikProps={BatchMilestoneFormProperties.formikProps}
      toClientDataFormat={BatchMilestoneFormProperties.toClientDataFormat}
      toServerDataFormat={BatchMilestoneFormProperties.toServerDataFormat}
      fetch={{
        id: id,
        url: BatchMilestoneApiUrls.GET
      }}
    />
  )
}

export default BatchMilestoneViewForm
