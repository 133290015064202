import { Yup } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import {
    constants,
} from 'cng-web-lib'

const {
    locale: {
        key: {
            CommonValidationMessageKeys,
        },
    },
} = constants

function BatchBookingRequestMakeValidationSchema(translate) {
    let requiredMessage = translate(
        Namespace.COMMON_VALIDATION_MSG,
        CommonValidationMessageKeys.REQUIRED
    )
    let dateTypeErrorMessage = translate(
        Namespace.COMMON_VALIDATION_MSG,
        CommonValidationMessageKeys.DATE_TYPE_ERROR
    )

    return Yup.object({
        party: Yup.string(),
        senderCode: Yup.string(),
        senderQualifier: Yup.string(),
        receiverQualifier: Yup.string(),
        messageReferenceNo: Yup.string(),
        messageType: Yup.string(),
        carrierName: Yup.string(),
        messageFunction: Yup.string(),
        documentMsgNo: Yup.string(),
        documentIssuedOnDate: Yup.string(),
        documentIssuedOnTime: Yup.string(),
        acknowledgementIndicator: Yup.string(),
        bookingReferenceNo: Yup.string(),
        orderNumber: Yup.string(),
        shipperRemark: Yup.string(),
        bookingShipmentType: Yup.string(),
        stageCode: Yup.string(),
        consignorReferenceNo: Yup.string(),
        voyageNo: Yup.string(),
        vesselName: Yup.string(),
        portOfLoadingPortCode: Yup.string(),
        portOfLoeadingPortCodeList: Yup.string(),
        portOfLoadingDateCode: Yup.string(),
        portOfLoadingDate: Yup.string(),
        portOfLoadingTime: Yup.string(),
        portOfDischargePortCode: Yup.string(),
        portOfDischargePortCodeList: Yup.string(),
        placeOfReceiptPortName: Yup.string(),
        placeOfReceiptDateCode: Yup.string(),
        placeOfReceiptDate: Yup.string(),
        placeOfDeliveryPortName: Yup.string(),
        placeOfDeliveryDateCode: Yup.string(),
        placeOfDeliveyDate: Yup.string(),
        consignorName: Yup.string(),
        consigneeName: Yup.string(),
        consigneeAddress: Yup.string(),
        containerTypesAndSizes: Yup.string(),
        socOrCocIndicator: Yup.string(),
        fullOrEmptyIndicator: Yup.string(),
        totalQuantity: Yup.string(),
        bookingId: Yup.number(),
        fileType: Yup.string().required(requiredMessage),
        source: Yup.string().required(requiredMessage),
        recordStatus: Yup.string().required(requiredMessage),
        contactPersonName: Yup.string(),
        emailAddress: Yup.string(),
        processedDate: Yup.date().validFormat("YYYY-MM-DD HH:mm:ss").typeError("Invalid date format. Only \"YYYY-MM-DD HH:mm:ss\" format is valid.").required(requiredMessage),
    })
}

export default BatchBookingRequestMakeValidationSchema