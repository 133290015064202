import makeValidationSchema from './CodeMappingMakeValidationSchema'
import { useTranslation } from 'cng-web-lib'
import React from 'react'
import Namespace from 'src/constants/locale/Namespace'
import CodeMappingKeys from 'src/constants/locale/key/CodeMapping'
import { Grid } from '@material-ui/core'
import {
    components,
    DataFlattener
} from 'cng-web-lib'

const {
    form: {
        field: {
            CngTextField,
            CngCodeMasterAutocompleteField
        },
    },
    CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
    platform: "",
    type: "",
    calistaCode: "",
    ediCode: "",
    codeDesc: "",
})

const FORMIK_PROPS = {
    initialValues: { ...DEFAULT_INITIAL_VALUES },
    makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, showNotification, shouldHideMap }) {
    const { translate } = useTranslation(Namespace.CODE_MAPPING)
    const translatedTextsObject = makeTranslatedTextsObject()

    function makeTranslatedTextsObject() {
        let codeMapping = translate(
            Namespace.CODE_MAPPING,
            CodeMappingKeys.TITLE
        )
        let platform = translate(
            Namespace.CODE_MAPPING,
            CodeMappingKeys.PLATFORM
        )
        let type = translate(
            Namespace.CODE_MAPPING,
            CodeMappingKeys.TYPE
        )
        let calistaCode = translate(
            Namespace.CODE_MAPPING,
            CodeMappingKeys.CALISTA_CODE
        )
        let ediCode = translate(
            Namespace.CODE_MAPPING,
            CodeMappingKeys.EDI_CODE
        )
        let codeDesc = translate(
            Namespace.CODE_MAPPING,
            CodeMappingKeys.CODE_DESC
        )

        return {
            codeMapping,
            platform,
            type,
            calistaCode,
            ediCode,
            codeDesc
        }
    }

    return (
        <Grid container spacing={3}>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.platform}>
                <CngCodeMasterAutocompleteField
                    name='platform'
                    label={translatedTextsObject.platform}
                    disabled={disabled}
                    codeType='EDI_PLATFORM_CODE'
                />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.type}>
                <CngCodeMasterAutocompleteField
                    name='type'
                    label={translatedTextsObject.type}
                    disabled={disabled}
                    codeType='EDI_CODE_TYPE'
                />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.calistaCode}>
                <CngTextField
                    name="calistaCode"
                    label={translatedTextsObject.calistaCode}
                    disabled={disabled}
                    inputProps={{ maxLength: 5 }}
                />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.ediCode}>
                <CngTextField
                    name="ediCode"
                    label={translatedTextsObject.ediCode}
                    disabled={disabled}
                    inputProps={{ maxLength: 5 }}
                />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.codeDesc}>
                <CngTextField
                    name="codeDesc"
                    label={translatedTextsObject.codeDesc + " *"}
                    disabled={disabled}
                />
            </CngGridItem>
        </Grid>
    )
}

function toClientDataFormat(serverData) {
    let localData = DataFlattener.parse(serverData);
    return localData;
}

function toServerDataFormat(localData) {
    return DataFlattener.unflatten(localData);
}

const FormProperties = Object.freeze({
    formikProps: FORMIK_PROPS,
    Fields: Fields,
    toClientDataFormat: toClientDataFormat,
    toServerDataFormat: toServerDataFormat
})

export default FormProperties
