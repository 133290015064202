import BatchBookingRequestApiUrls from 'src/apiUrls/BatchBookingRequestApiUrls'
import BatchBookingRequestFormProperties from './BatchBookingRequestFormProperties'
import React from 'react'
import { components } from 'cng-web-lib'

const {
  form: { CngEditForm }
} = components

function BatchBookingRequestEditForm({
  showNotification,
  id,
  onFetchPreSuccess = () => { 
    //do nothing
   }
}) {
  return (
    <CngEditForm
      fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
      showNotification={showNotification}
      bodySection={
        <BatchBookingRequestFormProperties.Fields
          disabled={false}
          showNotification={showNotification}
        />
      }
      formikProps={BatchBookingRequestFormProperties.formikProps}
      toClientDataFormat={BatchBookingRequestFormProperties.toClientDataFormat}
      toServerDataFormat={BatchBookingRequestFormProperties.toServerDataFormat}
      fetch={{
        url: BatchBookingRequestApiUrls.GET,
        onPreSuccess: onFetchPreSuccess
      }}
      update={{
        url: BatchBookingRequestApiUrls.PUT
      }}
      id={id}
    />
  )
}

export default BatchBookingRequestEditForm
