import { Container, Grid, Paper } from '@material-ui/core'
import React, { useState } from 'react'
import { components, useTranslation } from 'cng-web-lib'

import BatchMilestoneViewForm from './BatchMilestoneViewForm'
import { useParams } from 'react-router-dom'
import Namespace from 'src/constants/locale/Namespace'
import BatchMilestoneKeys from 'src/constants/locale/key/BatchMilestone'

const { CngTabs } = components

function ViewPage({ showNotification }) {
  const { id } = useParams()
  const { translate } = useTranslation([Namespace.BATCH_MILESTONE])
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let batchMilestone = translate(
      Namespace.BATCH_MILESTONE,
      BatchMilestoneKeys.TITLE
    )

    return {
      batchMilestone
    }
  }

  return (
    <Grid container spacing={3}>
      {/* <Grid item xs={12}>
        <Paper>
          <Container maxWidth='xl'>
            <BatchMilestoneViewForm
              showNotification={showNotification}
              id={id}
              onFetchPreSuccess={(datum) => {
              }}
            />
          </Container>
        </Paper>
      </Grid> */}
      <Grid item xs={12}>
        <CngTabs
          headerColor='primary'
          tabs={[
            {
              tabName: translatedTextsObject.batchMilestone,
              tabContent: (
                <BatchMilestoneViewForm
                  showNotification={showNotification}
                  id={id}
                />
              )
            },
          ]}
        />
      </Grid>
    </Grid>
  );
}

export default ViewPage